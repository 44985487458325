import {
  checkCircle,
  pencil,
  legalScale,
  advertisingMegaphone,
  faceId,
  headphonesCustomerSupport,
  messagesBubbleSquareText,
  buy,
  leaf,
} from 'icons/default';
import { CarActionsCheck } from 'icons/symbol';

const howToItemsBuy = [
  {
    src: '/assets/images/how-to-buy-1.png',
    title: '1. Hitta rätt bil för dig',
    text: 'Hittat en ny bil på Blocket? Börja med att fylla i registreringsnumret på Blipp för en snabb kontroll.',
    icon: CarActionsCheck,
  },
  {
    src: '/assets/images/how-to-buy-2.png',
    title: '2. Bestäm tid och plats',
    text: 'Bestäm tid och plats med säljaren för att titta på bilen. Vi är med om du väljer att slå till!',
    icon: messagesBubbleSquareText,
  },
  {
    src: '/assets/images/how-to-buy-3.png',
    title: '3. Dags för köp – vi är med',
    text: 'Våra rådgivare är med via telefon och hjälper dig med allt från digitala kontrakt och finansiering till överlämning.',
    icon: headphonesCustomerSupport,
  },
];

const howToItemsBuyBlocketCar = [
  {
    src: '/assets/images/how-to-buy-blocket.png',
    title: 'Hitta en bil som passar dig och dina behov.',
    text: 'Med Blipp kan du köpa nästan vilket begagnat fordon du vill. Hitta en bil på Blocket, kontrollera den på Blipp och slipp obehagliga överraskningar.',
  },
  {
    src: '/assets/images/how-to-buy-2.png',
    title: 'Skicka ett bud till säljaren eller boka en tid med Blipp.',
    text: 'Skicka ett bud till säljaren via Blipp eller boka en tid med oss om ni redan är överens. Välj sen hur du vill betala — direktöverföring, faktura eller billån.',
  },
  {
    src: '/assets/images/how-to-buy-3.png',
    title: 'Dags för köp – vi är med dig hela vägen.',
    text: 'Våra rådgivare är med via telefon och hjälper dig med visuell kontroll, provkörning, eventuella restskulder, digitalt kontrakt, överlämning, garanti och försäkring.',
  },
];

const howToItemsSell = [
  {
    src: '/assets/images/how-to-sales-2-v4.png',
    title: '1. Annonsera',
    text: 'Beskriv skick, utrustning och ladda upp bilder på bilen så publicerar vi din annons på Blipp.',
    icon: advertisingMegaphone,
  },
  {
    src: '/assets/images/how-to-sales-3.png',
    title: '2. Blipp hittar köpare',
    text: 'På Blipp kan du svara på frågor från intresserade köpare som även kan skicka bud på din bil. När ni är överens bokar ni enkelt en tid att ses för visning av bilen.',
    icon: faceId,
  },

  {
    src: '/assets/images/how-to-sales-4.png',
    title: '3. Dags för affär!',
    text: 'Våra rådgivare är med via telefon och hjälper dig med allt från digitala kontrakt till lösen av eventuella lån. Det enda du behöver ha med dig är ägarbeviset och mobilt BankID.',
    icon: headphonesCustomerSupport,
  },
];

const howToItemsLoan = [
  {
    src: '/assets/images/how-to-loan-1.png',
    title: '1. Ansök om billån',
    text: 'Hitta en bil du vill köpa och fyll i ansökan digitalt på Blipp. Välj hur mycket du vill lägga i kontantinsats (minst 20%) och lånetid.',
    icon: pencil,
  },
  {
    src: '/assets/images/how-to-loan-2.png',
    title: '2. Boka tid med blipp',
    text: 'Bestäm tid och plats med säljaren för att titta på bilen och boka tid med Blipp. Vi är med om du väljer att slå till!',
    icon: checkCircle,
  },
  {
    src: '/assets/images/how-to-loan-3.png',
    title: '3. Experthjälp på garageuppfarten',
    text: 'Våra rådgivare är med via telefon och hjälper dig med allt det krångliga. Experthjälp helt enkelt!',
    icon: headphonesCustomerSupport,
  },
];

const howToItemsPrivateLoan = [
  {
    src: '/assets/images/how-to-loan-1.png',
    title: 'Ansök om lånelöfte',
    text: 'För den som inte vill eller kan betala en kontantinsats lanserar Blipp nu ett privatlån som du kan använda för bilköpet. Du kan låna till hela köpesumman utan krav på kontantinsats.',
  },
  {
    src: '/assets/images/how-to-loan-2.png',
    title: 'Lånelöfte i 30 dagar',
    text: 'Förbered ditt köp genom att ansöka om ett lånelöfte via vår partner Moank. Med ett beviljat lånelöfte kan du köpa en bil som du hittar på exempelvis Blipp, av någon du känner eller på Blocket. När det är dags för affär betalas pengarna ut via Blipp som sköter transaktionen.',
  },
  {
    src: '/assets/images/how-to-loan-3.png',
    title: 'Privatlån eller billån',
    text: 'Med Blipp kan du köpa en bil från en privatperson med bilen som säkerhet för lånet, då behöver du även betala en kontantinstats på 20%. Vill du ta ett lån utan kontantinsats så passar vårat privatlån bättre.',
  },
];

const howToItemsRelease = [
  {
    src: '/assets/images/how-to-loan-2.png',
    title: '1. Gör din ansökan',
    text: 'Fyll i din ansökan digitalt på Blipp. Det tar mindre än 1 minut.',
    icon: pencil,
  },
  {
    src: '/assets/images/how-to-loan-3.png',
    title: '2. Få ett nytt erbjudande',
    text: 'Våra experter går igenom dina nuvarande villkor och ger dig bästa möjliga förslag på ett nytt upplägg.',
    icon: legalScale,
  },
  {
    src: '/assets/images/how-to-release.png',
    title: '3. Vi löser det krångliga',
    text: 'Luta dig tillbaka medan vi löser det krångliga',
    icon: checkCircle,
  },
];

const howToItemsCompanyLeasing = [
  {
    src: '/assets/images/how-to-loan-2.png',
    title: 'Fast månadskostnad – Budgetera ditt bilägande',
    text: 'Många företagare uppskattar också det bekväma bilägandet som det ofta innebär att leasa bil. Företaget slipper ekonomiska överraskningar och kan budgetera hur mycket bilägandet kommer att kosta.',
    icon: buy,
  },
  {
    src: '/assets/images/how-to-loan-3.png',
    title: 'Spara 1000-lappar i skatt',
    text: 'Begagnade tjänstebilar har blivit ett skatteklipp jämfört med att köpa en helt ny bil. Tjänstebilsförare som väljer en nyare begagnad bil kan spara 28 000 kronor i skatt på tre år.',
    href: '/magazine/spara-pengar-foretagsleasing',
    buttonText: 'Läs mer',
    icon: checkCircle,
  },
  {
    src: '/assets/images/how-to-release.png',
    title: 'Bra för klimatet',
    text: 'Var snäll mot Moder Jord och byt inte bil oftare än nödvändigt. Det tar tid innan en ny bil blir klimatneutral jämfört mot att behålla din nuvarande bil.',
    icon: leaf,
  },
];

export const howToCardsBuy = {
  title: 'Så funkar det att köpa med Blipp.',
  intro:
    'Vi hjälper dig med allt från kontroll till överlämning, garanti och försäkring så att du kan fokusera på det viktiga.',
  howToItems: howToItemsBuy,
};

export const howToCardsBuyBlocketCar = {
  title: 'Så funkar det att köpa bil på Blocket med hjälp av Blipp.',
  intro:
    'Genom oss på Blipp kan du få hjälp att göra ditt privata bilköp lika tryggt som hos bilhandlaren.',
  howToItems: howToItemsBuyBlocketCar,
};

export const howToCardsSell = {
  title: 'Så här säljer du med Blipp',
  intro:
    'Låt oss sköta allt det krångliga. Vi löser allt från kontrakt, överlämning och betalning samtidigt som du får bättre betalt. Dessutom finns våra rådgivare med hela tiden.',
  howToItems: howToItemsSell,
};

export const howToCardsLoan = {
  title: 'Billån med Blipp',
  howToItems: howToItemsLoan,
};

export const howToCardsPrivateLoan = {
  howToItems: howToItemsPrivateLoan,
};

export const howToCardsRelease = {
  title: 'Hur fungerar det?',
  howToItems: howToItemsRelease,
};

export const howToCardsCompanyLeasing = {
  howToItems: howToItemsCompanyLeasing,
};
