import React from 'react';

import useMediaQuery from 'utils/useMediaQuery';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import { arrowRight } from 'icons/default';

import { Cards } from './Cards';
import { HowToItem } from './types';
import styles from './HowToCards.module.scss';

interface Props {
  title?: string;
  intro?: string;
  howToItems: HowToItem[];
  href?: string;
  buttonText?: string;
  hideImages?: boolean;
  showBg?: boolean;
  showIcons?: boolean;
}

export const HowToCards: React.FC<Props> = ({
  title,
  intro,
  howToItems,
  href,
  buttonText,
  hideImages,
  showBg,
  showIcons,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className={styles.wrapper}>
      <div className={styles.intro}>
        {title && <h2>{title}</h2>}
        {intro && <p>{intro}</p>}
      </div>
      <Cards showIcons={showIcons} showBg={showBg} items={howToItems} hideImages={hideImages} />
      <div>
        {href && buttonText && (
          <Button
            type={ButtonType.Button}
            href={href}
            variant={ButtonVariant.PrimaryMintSweet}
            size={ButtonSize.Large}
            trailingIcon={arrowRight}
            stretch={isMobile}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};
