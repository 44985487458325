import React from 'react';
import Image from 'next/image';

import useMediaQuery from 'utils/useMediaQuery';
import { List } from 'components/List';
import { Button, ButtonSize, ButtonType, ButtonVariant } from 'components/Button';
import { ListItemSize } from 'components/List/ListItem';
import { Colors } from 'enums/Colors';
import { Icon, IconType } from 'components/tokens/Icon';
import { arrowRight } from 'icons/default';

import { HowToItem } from './types';
import styles from './Cards.module.scss';

interface Props {
  items: HowToItem[];
  hideImages?: boolean;
  showBg?: boolean;
  showIcons?: boolean;
}

export const Cards: React.FC<Props> = ({ items, hideImages, showBg, showIcons }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <div className={styles.wrapper}>
      {items.map((card) => (
        <div
          key={card.title}
          className={`${styles['card-container']} ${showBg ? styles.showBg : ''}`}
        >
          {!hideImages && (
            <div className={styles['image-container']}>
              <Image
                alt={card.title}
                src={card.src}
                layout="fill"
                objectFit="contain"
                quality={90}
              />
            </div>
          )}
          {showIcons && (
            <span className={styles.icons}>
              <Icon icon={card.icon} size={55} type={IconType.Stroke} />
            </span>
          )}
          <h3>{card.title}</h3>
          <p>{card.text}</p>
          {card.list && (
            <List
              items={card.list}
              size={ListItemSize.Large}
              bgColor={Colors.Transparent}
              listItemLabelColor={Colors.StoneGrey100}
            />
          )}
          {card.href && card.buttonText && (
            <Button
              type={ButtonType.Button}
              href={card.href}
              variant={ButtonVariant.Primary}
              size={ButtonSize.Large}
              trailingIcon={arrowRight}
              stretch={isMobile}
            >
              {card.buttonText}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};
